import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./components/pages/admin/Admin";
import Bar from "./components/pages/bar/Bar";
import Fooders from "./components/pages/fooders/Fooders";
import Login from "./components/pages/login/Login";
import NotFoundPage from "./components/pages/unprotected/NotFoundPage";


const App = () => {
  // const { planData } = useToast();
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            {localStorage.getItem('staff_type') === 'fooder' && <Route path="/" element={<Fooders />}></Route>}
            {localStorage.getItem('staff_type') === 'bar' && <Route path="/" element={<Bar />}></Route>}
            {localStorage.getItem('staff_type') === 'admin' && <Route path="/" element={<Admin />}></Route>}


            <Route path="/fooder" element={<Fooders />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/bar" element={<Bar />}></Route>


            {!localStorage.getItem('staff_type') && <Route path="/" element={<Login />}></Route>}
            <Route path="/login" element={<Login />}></Route>

            {/* <Route path="/login" element={<Login />}></Route>

            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/bar" element={<Bar />}></Route> */}
            <Route path="*" element={<NotFoundPage />}></Route>


          </Routes>

        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
